/* eslint-disable no-console */
// Packages
import { Plugin } from "@rematch/core";
import { toast } from "@seidor/orbit-ds-core";
import { instance as client } from "../../components/Modules/client";

// Interfaces
import { FetchConfig, FetchPayload } from "./types";

// Helpers
import validateConfig from "./utils";
import HandleErrors from "../handleErrors";

type generic = Record<string, unknown>;

export default (config?: FetchConfig): Plugin<any> => {
	// Validate plugin
	validateConfig(config);
	const controllerName = config?.controllerName || "controller";

	// -------------------------------------------------
	// Config response
	// -------------------------------------------------

	return {
		onModel(model, store: any): void {
			// ignore items not in whitelist
			if (config?.whitelist && !config.whitelist.includes(model.name)) return;

			// ignore items in blacklist
			if (config?.blacklist && config.blacklist.includes(model.name)) return;

			const actions = (store.dispatch as generic)[model.name] as generic;

			// map effects
			Object.keys(actions).forEach((key) => {
				// only map effects
				if ((actions[key] as generic).isEffect !== true) return;

				const actionType = `${model.name}/${key}`;

				// ignore items not in whitelist
				if (config?.whitelist && !config.whitelist.includes(actionType)) return;

				// ignore items in blacklist
				if (config?.blacklist && config.blacklist.includes(actionType)) return;

				// copy orig effect pointer
				const origEffect = actions[key] as (...props: generic[]) => FetchPayload;
				const controller = (store.dispatch as generic)[controllerName] as generic;

				// wrapper
				const wrapper = async (...props: generic[]) => {
					// waits for dispatch function to finish before calling "hide"
					const prerequest = await origEffect(...props);
					const request = {
						...prerequest,
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							...prerequest?.headers,
						},
					} as FetchPayload;

					// make request
					if (request && typeof request === "object" && request.endpoint) {
						if (
							(request.loader === undefined || request.loader)
							&& store.getState()[controllerName].loading === false
						) {
							await (controller.setLoading as (
								state: boolean
							) => Promise<void>)(true);
						}

						const method = request.method || "get";
						const data = request.before
							? await request.before(request.data || {})
							: request.data || {};

						try {
							const response = (await (client[method] as any)(
								request.endpoint,
								["get", "delete"].includes(method) ? request : JSON.stringify(data),
								!["get", "delete"].includes(method) ? request : undefined,
							));

							// Get data
							const rawresponsedata = request.retrieveOn
								? request.retrieveOn
									.split(".")
									.reduce((prev, curr) => prev[curr], response.data)
								: response.data;
							const responsedata = request.filter
								? await request.filter(rawresponsedata)
								: rawresponsedata;

							if (request.after) await request.after(responsedata);

							if (response.status >= 400) {
								if (request.catch) await request.catch(responsedata);
								const message = responsedata?.data?.message
									|| request.errorMessage
									|| "Houve um problema ao completar a solicitação";

								await (controller.addErrors as (
										obj: string[]
									) => Promise<void>)([message]);

								toast({
									delay: 5000,
									type: "attention",
									description: message,
									title: "Atenção!",
								});
								HandleErrors(responsedata);
							} else {
								// Save to state
								if (request.dispatch) {
									await (actions[request.dispatch] as (_: unknown) => void)(
										responsedata,
									);
								}

								if (request.message) {
									toast({
										delay: 5000,
										type: "success",
										description: request.message,
										title: "Sucesso!",
									});
								}
							}

							// Finish loading
							if (
								(request.loader === undefined || request.loader)
								&& store.getState()[controllerName].loading === true
							) {
								await (controller.setLoading as (
									state: boolean
								) => Promise<void>)(false);
							}

							if (response.status >= 400) {
								return response
							}

							return responsedata;
						} catch (error: any) {
							if (request.catch) await request.catch(error as generic);
							const message =								error.response?.data.message
								|| request.errorMessage
								|| "Houve um problema ao completar a solicitação";

							if (
								(request.loader === undefined || request.loader)
								&& store.getState()[controllerName].loading === true
							) {
								await (controller.setLoading as (
									state: boolean
								) => Promise<void>)(false);
							}

							await (controller.addErrors as (
								obj: string[]
							) => Promise<void>)([message]);

							toast({
								delay: 5000,
								type: "attention",
								description: message,
								title: "Atenção!",
							});
							HandleErrors(error);
						}

						return undefined;
					}

					return request;
				};

				wrapper.isEffect = true;

				// replace existing effect with new wrapper
				actions[key] = wrapper;
			});
		},
	};
};
