import cookie from "js-cookie";
import crypto from "crypto-js";
import { withRouter } from "react-router";
import { toast } from "@seidor/orbit-ds-core";
import Service from "../../components/Modules/service";

import Store, { Models } from "../index";

const passHash = process.env.REACT_APP_PASS_HASH as string;

@Service.store(Store, "main.controller")
class ControllerService extends Service<Models, "main.controller"> {
	async login(data: { email: string, password: string; }) {
		const [, dispatch] = this.model;
		const encryptdPassword = crypto.AES.encrypt(data.password, passHash).toString();

		try {
			dispatch.setLoading(true);

			const response = await this.request.post("/auth", {
				username: data.email,
				cPassword: encryptdPassword,
				isAdmPortal: true,
			});

			if (response.status === 200 && response.data.data.tenants.length > 0) {
				dispatch.setUser(response.data.data);

				cookie.set("token", response.data.token);
				cookie.set("accessToken", response.data.accessToken);
				cookie.set("tenant", response.data.data.pk);

				this.request.setToken(response.data.token);
			} else if (response.status === 400 && response.data.message === "Incorrect username or password.") {
				toast({
					delay: 5000,
					type: "attention",
					description: "Os dados de acesso estão incorretos.",
					title: "Atenção!",
				});
			}
		} catch (error) {
			toast({
				delay: 5000,
				type: "error",
				description: "Ocorreu um erro ao fazer login.",
				title: "Atenção!",
			});
		} finally {
			dispatch.setLoading(false);
		}
	}

	logout(redirect = false) {
		const [, dispatch] = this.model;

		dispatch.setUser(undefined);

		cookie.remove("token");
		cookie.remove("accessToken");
		cookie.remove("tenant");

		this.request.clearToken();

		if (redirect) {
			withRouter((props) => { props.history.push("/login"); return null; });
		}
	}

	async isLogged() {
		const [, dispatch] = this.model;

		dispatch.setLoading(true);

		const token = cookie.get("token");
		const accessToken = cookie.get("accessToken");
		const tenant = cookie.get("tenant");

		if (token && accessToken && tenant) {
			this.request.setToken(token as string);

			this.request.get("auth", {
				headers: {
					token,
					accessToken,
					"x-api-key": tenant,
				},
			}).then((response) => {
				if (response.status === 200) {
					dispatch.setUser(response.data);
				} else {
					this.logout(true);

					toast({
						delay: 5000,
						type: "attention",
						description: "Sua sessão expirou, favor autenticar a sua conta novamente.",
						title: "Atenção!",
					});
				}
			}).catch(() => {
				this.logout(true);

				toast({
					delay: 5000,
					type: "attention",
					description: "Sua sessão expirou, favor autenticar a sua conta novamente.",
					title: "Atenção!",
				});
			});
		} else {
			this.logout(true);
		}

		dispatch.setLoading(false);
	}

	async forgotPassword(data: { email: string; }) {
		const [, dispatch] = this.model;

		dispatch.setLoading(true);

		const response = await this.request.post("users/forgotpassword", {
			username: data.email,
		});

		dispatch.setLoading(false);

		if (response.status === 200) {
			toast({
				delay: 5000,
				type: "success",
				description: "Sucesso! Por favor verifique seu e-mail.",
				title: "Sucesso!",
			});
		} else {
			toast({
				delay: 5000,
				type: "error",
				description: "Ocorreu um erro inesperado, tente novamente.",
				title: "Atenção!",
			});
		}
	}

	async newPassword(data: { username: string, newPassword: string, confirmationCode: string; }) {
		const [, dispatch] = this.model;
		const encryptdPassword = crypto.AES.encrypt(data.newPassword, passHash).toString();

		dispatch.setLoading(true);

		const response = await this.request.post("user/confirmpassword", {
			username: data.username,
			cNewPassword: encryptdPassword,
			confirmationCode: data.confirmationCode,
		});

		dispatch.setLoading(false);

		if (response.status === 200) {
			toast({
				delay: 5000,
				type: "success",
				description: "Sucesso! Tente efetuar o login com a nova senha.",
				title: "Sucesso!",
			});
		} else {
			toast({
				delay: 5000,
				type: "error",
				description: "Ocorreu um erro ao definir a nova senha.",
				title: "Atenção!",
			});
		}
	}
}

const model = new ControllerService();
export default model;
