// Import
import ServiceClass from "./parts/class";
import ServiceDecorator from "./parts/decorator";
import ServiceResource from "./parts/resource.class";

// Build export
const Service = ServiceClass;
Service.store = ServiceDecorator;
Service.resource = ServiceResource;

export default Service;
