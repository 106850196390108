// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import {
	IActions, IcreateNewLinkProps, IGetByModuleBySubmoduleProps, IPromotionMainProps,
} from "../../interfaces/actions";

export default createModel<IActions>({
	name: "module.actions",
	endpoint: {
		general: "/authservice/api/services",
	},
	messages: {
		success: {
			update: "Serviço atualizado com sucesso",
			create: "Serviço criado com sucesso",
		},
		error: {
			general: "Ocorreu um erro ao buscar os serviços",
			update: "Ocorreu um erro ao atualizar o serviço",
			create: "Ocorreu um erro ao criar o serviço",
		},
	},
	effects: () => ({
		getAll: () => {
			return {
				endpoint: "/authservice/api/services?page=1&size=100&sortParam=name&sortOrder=asc",
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os serviço",
			}
		},
		getById: (id: string) => {
			return {
				endpoint: `/authservice/api/services/${id}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o serviço.",
			}
		},
		active: (id: string) => {
			return {
				endpoint: `/authservice/api/services/${id}/enable`,
				method: "put",
				message: "Serviço ativado com sucesso.",
				errorMessage: "Ocorreu um erro ao ativar o serviço.",
			}
		},
		inactive: (id: string) => {
			return {
				endpoint: `/authservice/api/services/${id}/disable`,
				method: "put",
				message: "Serviço inativado com sucesso.",
				errorMessage: "Ocorreu um erro ao inativar o serviço.",
			}
		},
		update: (data: any) => {
			return {
				endpoint: `/authservice/api/services/${data.service_id}`,
				data,
				method: "put",
				message: "Serviço atualizado com sucesso",
				errorMessage: "Ocorreu um erro ao atualizar o serviço",
			}
		},
		create: (data: any) => {
			return {
				endpoint: "/authservice/api/services",
				data,
				method: "post",
				message: "Serviço criado com sucesso",
				errorMessage: "Ocorreu um erro ao criar o serviço",
			}
		},
		createNewLink: (data: IcreateNewLinkProps) => {
			return {
				endpoint: "/authservice/api/links",
				data,
				method: "post",
				message: "Associação realizada com sucesso.",
				errorMessage: "Ocorreu um erro ao realizar a associação.",
			}
		},
		removeLink: (id: string) => {
			return {
				endpoint: `/authservice/api/links/${id}`,
				method: "delete",
				message: "Associação excluída com sucesso",
				errorMessage: "Ocorreu um erro ao remover a associação.",
			}
		},
		promotionMain: (data: IPromotionMainProps) => {
			return {
				endpoint: "/authservice/api/links/promotemain",
				data,
				method: "post",
				message: "Associação atualizada com sucesso.",
				errorMessage: "Ocorreu um erro ao atualizar a associação.",
			}
		},
		getByModuleBySubmodule: (data: IGetByModuleBySubmoduleProps) => {
			return {
				endpoint: `/authservice/api/services/${data.tenant_id}/tenant`,
				data,
				method: "post",
				errorMessage: "Ocorreu um erro ao buscar os serviços.",
			}
		},
	}),
});
