// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import {
	IAddAssociation,
	IAddAuthorization,
	ICreateData,
	ITenants,
	IUpdateData,
} from "../../interfaces/tenants";

export default createModel<ITenants>({
	name: "module.tenants",
	endpoint: {
		general: "/tenants",
	},
	messages: {
		success: {
			update: "Tenant atualizado com sucesso",
			create: "Tenant criado com sucesso",
		},
		error: {
			general: "Ocorreu um erro ao buscar os tenants",
			update: "Ocorreu um erro ao atualizar o tenant",
			create: "Ocorreu um erro ao criar o tenant",
		},
	},
	effects: () => ({
		getAll: () => {
			return {
				endpoint: "/tenants?page=1&size=50000&sortParam=name&sortOrder=asc",
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os usuários",
			};
		},
		create: (data: ICreateData) => {
			return {
				endpoint: "/tenants",
				data,
				method: "post",
				message: "Tenant criado com sucesso",
				errorMessage: "Ocorreu um erro ao criar o tenant",
			};
		},
		update: (data: IUpdateData) => {
			return {
				endpoint: `tenants/${data.id}`,
				data,
				method: "put",
				message: "Tenant atualizado com sucesso",
				errorMessage: "Ocorreu um erro ao atualizar o tenant",
			};
		},
		getById: (id: string) => {
			return {
				endpoint: `/tenants/${id}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o Tenant.",
			};
		},
		active: (id: string) => {
			return {
				endpoint: `tenants/${id}/enable`,
				method: "put",
				message: "Tenant ativado com sucesso.",
				errorMessage: "Ocorreu um erro ao ativar o tenant.",
			};
		},
		inactive: (id: string) => {
			return {
				endpoint: `tenants/${id}/disable`,
				method: "put",
				message: "Tenant inativado com sucesso.",
				errorMessage: "Ocorreu um erro ao inativar o tenant.",
			};
		},
		addAssociation: (data: IAddAssociation) => {
			return {
				endpoint: "/authservice/api/tenantAccess",
				data,
				method: "post",
				message: "Módulo associado com sucesso",
				errorMessage: "Ocorreu um erro ao associar o módulo",
			};
		},
		removeAssociation: (id: string) => {
			return {
				endpoint: `/authservice/api/tenantAccess/${id}`,
				method: "delete",
				message: "Módulo excluído com sucesso",
				errorMessage: "Ocorreu um erro ao remover o módulo",
			};
		},
		addAuthorization: (data: IAddAuthorization) => {
			return {
				endpoint: "/authservice/api/tenantAuthorizations",
				data,
				method: "post",
				message: "Autorização associada com sucesso",
				errorMessage: "Ocorreu um erro ao associar a autorização",
			};
		},
		removeAuthorization: (id: string) => {
			return {
				endpoint: `/authservice/api/tenantAuthorizations/${id}`,
				method: "delete",
				message: "Autorização excluída com sucesso",
				errorMessage: "Ocorreu um erro ao remover a autorização",
			};
		},
		getTenantAuth: ({
			id, size, page, sort,
		}: any) => {
			return {
				endpoint: `/authservice/api/tenantAuthorizations/${id}/tenant?size=${size}&page=${page}&sortParam=${sort.param}&sortOrder=${sort.order}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar as autorizações adicionadas.",
			};
		},
	}),
});
