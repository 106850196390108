// Interfaces
import { Options } from "../types";

// Data
import { setContext, addCallback, getContext } from "./data";

export default function options(opts: Options, cb: () => void): void {
	const precontext = getContext();
	const prefix = (precontext.prefix === undefined ? "" : precontext.prefix) + (opts.prefix || "");
	const c = { ...precontext, ...opts, prefix };

	addCallback(() => {
		setContext(c);

		cb();
	});
}
