// Packages
import React, {
	useEffect, useMemo, Suspense, lazy,
} from "react";
import {
	Route, useHistory, Switch,
} from "react-router-dom";
import route from "../../components/Modules/route";

// Services
import store from "../../models";
import AuthService from "../../models/controller";

// Components
import Header from "../../components/Header";
import LoaderSetter from "../../components/Loader";

// Views
import NotFound from "../../views/general/Error";

const types = {
	required: Route,
	forbidden: Route,
	optional: Route,
};

export default function App() {
	// -------------------------------------------------
	// Hooks
	// -------------------------------------------------

	const [state] = AuthService.useModel();
	const history = useHistory();

	// -------------------------------------------------
	// Memos
	// -------------------------------------------------

	const renderroutes = useMemo(() => {
		return route.build().map((item, index) => {
			const {
				view, path, exact, auth,
			} = item;
			const routeType = types[auth || "optional"] as typeof Route;
			const exactUse = exact !== false;

			return React.createElement(routeType, {
				exact: exactUse,
				key: index,
				path,
				component: function RouteWrap() {
					React.useEffect(() => {
						store.dispatch["main.controller"].setModule((item as any).module);
					}, []);

					const viewc = React.useMemo(() => {
						return lazy(() => import(`../../views/${view}`).catch(() => ({
							default: () => <NotFound />,
						})));
					}, []);

					return React.createElement(viewc);
				},
			} as any);
		});
	}, []);

	// -------------------------------------------------
	// Effects
	// -------------------------------------------------

	useEffect(() => {
		if (state.user as any !== false) {
			const { location: { pathname } } = window;

			if (state.user && (pathname === "/login" || pathname === "/passwordRecovery")) {
				history.push("/")
			}

			if (!state.user && pathname !== "/login" && pathname !== "/passwordRecovery") {
				history.push("/login")
			}
		}
	}, [history, state.user]);

	useEffect(() => {
		AuthService.isLogged();
	}, []);

	// -------------------------------------------------
	// Render
	// -------------------------------------------------

	return (
		<>
			<LoaderSetter loading={state.user as any === false} />
			<Suspense fallback="">
				{
					state.user
					&& <Header user={state.user} />
				}
				{
					state.user as any !== false
					&& <Switch>{renderroutes}</Switch>
				}
			</Suspense>
		</>
	);
}
