// Interfaces
import { Options } from "../types";

// Data
import { addRoute, getContext } from "./data";

export default function route(path: string, view: string, options: Options = {}): void {
	// gather
	const context = getContext();

	// build route
	addRoute(view, path, { ...context, ...options });
}
