// Packages
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@seidor/orbit-ds-core";
import { ReactComponent as Empty } from "../../../../img/svg/empty.svg";

// Style
import style from "./notFound.module.scss";

export default function Error() {
	const history = useHistory();

	// -------------------------------------------------
	// Render
	// -------------------------------------------------

	return (
		<div
			className={`${style.loginPageContainer} ${style.pageGrid} ${style.backgroundImage}`}
		>
			<div className={style.card}>
				<div>
					<h1>
						<strong>Ops,</strong>
						Parece que a página que
						<br />
						você procura não existe.
					</h1>
					<span>
						Não tem problema. Talvez você tenha digitado o
						{" "}
						<br />
						endereço errado ou a página foi movida.
					</span>
					<div className="flex mt-15">
						<div className="mr-15">
							<Button
								label="Voltar"
								icon="ph-arrow-left"
								iconPosition="left"
								onClick={history.goBack}
							/>
						</div>
						<div>
							<Button
								label="Ir para a Home"
								onClick={() => history.push("/")}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={style.imageArt}>
				<Empty />
			</div>
		</div>
	);
}
