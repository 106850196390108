// Packages
import { init, RematchDispatch, RematchRootState } from "@rematch/core";

// Models
import controllers from "./controller/model";
import tenant from "./tenant/model";
import users from "./users/model";
import tenants from "./tenants/model";
import modules from "./modules/model";
import submodules from "./submodules/model";
import authorizations from "./authorizations/model";
import actions from "./action/model";
import accesses from "./accesses/model";

// Utils
import requestFetch from "../utils/requestFetch"

// -------------------------------------------------
// Models
// -------------------------------------------------

const modelsObject = {
	"main.controller": controllers,
	tenant,
	"module.users": users,
	"module.tenants": tenants,
	"module.modules": modules,
	"module.submodules": submodules,
	"module.authorizations": authorizations,
	"module.actions": actions,
	"module.accesses": accesses,
};

// -------------------------------------------------
// Store
// -------------------------------------------------

const store = init({
	models: modelsObject,
	plugins: [
		requestFetch({
			controllerName: "main.controller",
		}),
	],
});
export default store;

// -------------------------------------------------
// Typings
// -------------------------------------------------

export type StoreInterface		= typeof store;
export type Models				= typeof modelsObject;
export type DispatchInterface	= RematchDispatch<typeof modelsObject>;
export type StateInterface		= RematchRootState<typeof modelsObject>;
