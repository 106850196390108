import { Model } from "@rematch/core";
import cookie from "js-cookie";
import { toast } from "@seidor/orbit-ds-core";

const tenant: Model<any> = {
	name: "tenant",
	state: {
		selectedTenant: "",
		tenants: [],
	},

	reducers: {
		addTenantsList: (state, payload) => {
			return {
				...state,
				tenants: payload,
			};
		},
		addTenant: (state, payload) => {
			cookie.set("apikey", payload);
			return {
				...state,
				selectedTenant: payload,
				errors: {},
			};
		},
		removeTenant: (state) => {
			return {
				...state,
				selectedTenant: "",
				errors: {},
			};
		},
	},

	effects: (dispatch) => ({
		async fetchNewTenants(data) {
			try {
				dispatch.tenant.addTenantsList(data);
			} catch (err) {
				toast({
					delay: 5000,
					type: "attention",
					description: "Ocorreu um problema ao carregar os tenants.",
					title: "Atenção!",
				});
			}
		},
		async fetchNewTenant(data) {
			try {
				dispatch.tenant.addTenant(data);
				toast({
					delay: 5000,
					type: "success",
					description: "Tenant associado com sucesso!",
					title: "Sucesso!",
				});
			} catch (err) {
				toast({
					delay: 5000,
					type: "attention",
					description: "Ocorreu um problema ao adicionar o Tenant. Por favor, tente novamente.",
					title: "Atenção!",
				});
			}
		},
	}),
};

export default tenant;
