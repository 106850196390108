/* eslint-disable react-hooks/rules-of-hooks */

// Packages
import React from "react";

// Interfaces
import { Models } from "@rematch/core";
import { IndexReturn, ShowReturn, IndexOptions } from "../../hookFactory/types";
import { StoreGeneric } from "../types";

// Decorators
import ServiceClass from "./class";

export default abstract class ResourceService<ModelGroup extends Models<any>, ModelName extends string> extends ServiceClass<ModelGroup, ModelName> {
	// -------------------------------------------------
	// use create hook
	// -------------------------------------------------

	public useCreate() {
		// store
		const [, dispatch] = this.model;

		const onCreate = React.useCallback(
			async (data) => {
				return dispatch.create(data);
			},
			[dispatch],
		);

		const onUpdate = React.useCallback(
			async (id, data) => {
				return dispatch.update({ id, data });
			},
			[dispatch],
		);

		const onDelete = React.useCallback(
			async (id) => {
				return dispatch.delete(id);
			},
			[dispatch],
		);

		const onClear = React.useCallback(async () => {
			return dispatch.clear();
		}, [dispatch]);

		return {
			create: onCreate, update: onUpdate, destroy: onDelete, clear: onClear,
		};
	}

	// -------------------------------------------------
	// use Index hook
	// -------------------------------------------------

	public useIndex(obj: IndexOptions) {
		// states
		const [lastopt, setlastopt] = React.useState<IndexOptions>({});

		// store
		const [store] = this.store as any;
		const [state, dispatch] = this.model as any;

		React.useEffect(() => {
			if (JSON.stringify(lastopt) !== JSON.stringify(obj)) {
				setlastopt(obj);
			}
		}, [store.tenant.selectedTenant, obj]);

		React.useEffect(() => {
			dispatch.index(lastopt);
		}, [store.tenant.selectedTenant, lastopt]);

		return state.list as IndexReturn<StoreGeneric<ModelGroup, ModelName>["model"]["0"]>;
	}

	// -------------------------------------------------
	// use Index hook
	// -------------------------------------------------

	public useShow(indexId: string) {
		// states
		const [id, setId] = React.useState(indexId);

		// store
		const [store] = this.store as any;
		const [, dispatch] = this.model;

		const onUpdate = React.useCallback(
			async (data) => {
				dispatch.update({ id, data });
			},
			[dispatch, id],
		);

		const onDelete = React.useCallback(async () => {
			dispatch.delete(id);
		}, [dispatch, id]);

		React.useEffect(() => {
			setId(indexId);
		}, [indexId]);

		React.useEffect(() => {
			dispatch.show(id);
		}, [id, store.tenant.selectedTenant]);

		return [
			store.data || false,
			onUpdate,
			onDelete,
		] as ShowReturn<StoreGeneric<ModelGroup, ModelName>["model"]["0"]>;
	}
}
