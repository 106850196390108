/* eslint-disable consistent-return */
import cookie from "js-cookie";
import { withRouter } from "react-router";
import { toast } from "@seidor/orbit-ds-core";

// Packages
import { createModel } from "@rematch/core";
import { instance as client } from "../../components/Modules/client";

// Interface
import UserInterface, { IChangePassword, IUpdateHome } from "../../interfaces/user";

const controller = createModel()({
	name: "main.controller",

	// -------------------------------------------------
	// States
	// -------------------------------------------------

	state: {
		user: false as unknown as UserInterface | undefined,
		module: undefined as { id: string, color?: string, label: string; } | undefined,
		loading: true,
	},

	// -------------------------------------------------
	// Reducers
	// -------------------------------------------------

	reducers: {
		setUser(state, user: UserInterface | undefined) {
			return {
				...state,
				user,
			};
		},
		setModule(state, module: { id: string, color?: string, label: string; } | undefined) {
			return {
				...state,
				module,
			};
		},
		setLoading(state, loading: boolean) {
			return {
				...state,
				loading,
			};
		},
		addErrors(state, errors: any) {
			return {
				...state,
				errors,
			};
		},
	},
	effects: (dispatch) => ({
		updateHome: (data: IUpdateHome) => {
			return {
				endpoint: `users/${data.user_id}`,
				data: {
					name: data.name,
					email: data.email,
					description: data.description,
					avatar: data.avatar,
				},
				method: "put",
				message: "Dados atualizados com sucesso.",
				errorMessage: "Ocorreu um erro ao atualizar os dados",
				after: dispatch["main.controller"].authLog,
			};
		},
		changePassword: (data: IChangePassword) => {
			return {
				endpoint: "users/changepassword",
				data: {
					oldPassword: data.oldPassword,
					newPassword: data.newPassword,
				},
				method: "post",
				message: "Senha alterada com sucesso.",
				errorMessage: "Ocorreu um erro ao alterar a senha.",
			};
		},
		authLog: () => {
			return {
				endpoint: "auth",
				after: dispatch["main.controller"].setUser,
			};
		},
		login: (data: { email: string, password: string; }) => {
			return {
				endpoint: "/auth",
				data: {
					username: data.email,
					password: data.password,
				},
				after: (response: any) => {
					dispatch.setLoading(true);

					dispatch.setLoading(false);

					if (response.status === 200 && response.data.data.tenants.length > 0) {
						dispatch.setUser(response.data.data);

						cookie.set("token", response.data.token);
						cookie.set("accessToken", response.data.accessToken);
						cookie.set("tenant", response.data.data.pk);

						client.setToken(response.data.token);
					} else if (response.status === 400 && response.data.message === "Incorrect username or password.") {
						toast({
							delay: 5000,
							type: "attention",
							description: "Os dados de acesso estão incorretos.",
							title: "Atenção!",
						});
					} else {
						toast({
							delay: 5000,
							type: "error",
							description: "Ocorreu um erro ao fazer login.",
							title: "Atenção!",
						});
					}
				},
			};
		},
		logout: (redirect = false) => {
			return {
				after: () => {
					dispatch.setUser(undefined);
					cookie.remove("token");
					cookie.remove("accessToken");
					cookie.remove("tenant");
					client.clearToken();

					if (redirect) {
						withRouter((props) => { props.history.push("/login"); return null; });
					}
				},
			};
		},
		isLogged: () => {
			const token = cookie.get("token");
			const accessToken = cookie.get("accessToken");
			const tenant = cookie.get("tenant");
			if (token && accessToken && tenant) {
				return {
					endpoint: "/auth",
					after: (response: any) => {
						dispatch.setLoading(true);
						client.setToken(token as string);

						if (response.data) {
							dispatch.setUser(response.data);
						} else {
							dispatch["main.controller"].logout(true);

							toast({
								delay: 5000,
								type: "attention",
								description: "Sua sessão expirou, favor autenticar a sua conta novamente.",
								title: "Atenção!",
							});
						}
					},
					catch: () => {
						dispatch["main.controller"].logout(true);

						toast({
							delay: 5000,
							type: "attention",
							description: "Sua sessão expirou, favor autenticar a sua conta novamente.",
							title: "Atenção!",
						});
					},
				};
			}
			dispatch["main.controller"].logout(true);

			dispatch.setLoading(false);
		},
		forgotPassword: (data: { email: string; }) => {
			return {
				endpoint: "user/forgotpassword",
				data: {
					username: data.email,
				},
				after: (response: any) => {
					dispatch.setLoading(true);

					dispatch.setLoading(false);

					if (response.status === 200) {
						toast({
							delay: 5000,
							type: "success",
							description: "Sucesso! Por favor verifique seu e-mail.",
							title: "Sucesso!",
						});
					} else {
						toast({
							delay: 5000,
							type: "error",
							description: "Ocorreu um erro inesperado, tente novamente.",
							title: "Atenção!",
						});
					}
				},
			};
		},
		newPassword: (data: { username: string, newPassword: string, confirmationCode: string; }) => {
			return {
				endpoint: "user/confirmpassword",
				data: {
					username: data.username,
					newPassword: data.newPassword,
					confirmationCode: data.confirmationCode,
				},
				after: (response: any) => {
					dispatch.setLoading(true);

					dispatch.setLoading(false);

					if (response.status === 200) {
						toast({
							delay: 5000,
							type: "success",
							description: "Sucesso! Tente efetuar o login com a nova senha.",
							title: "Sucesso!",
						});
					} else {
						toast({
							delay: 5000,
							type: "error",
							description: "Ocorreu um erro ao definir a nova senha.",
							title: "Atenção!",
						});
					}
				},
			};
		},
	}),

});

export default controller;
