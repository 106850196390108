// Parts
import { Options } from "../types";

// Data
import { addRoute, getContext } from "./data";

export default function fallback(arg2: string | Options, arg3?: string): void {
	// gather
	const options = arg3 ? arg2 : {};
	const view = arg3 || arg2;
	const context = getContext();

	// validate route
	if (typeof arg2 === "string" && typeof arg3 === "string") {
		throw new Error("You provided a view twice");
	}

	// add to stack
	addRoute(view as string, "", { ...context, exact: false, ...(options as Options) });
}
