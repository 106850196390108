/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Packages
import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@seidor/orbit-ds-core";
import packageJson from "../../../../package.json";

// Services
import AuthService from "../../models/controller";

// Parts
import IProps from "./types";
import { ReactComponent as Logo } from "../../../img/svg/logo.svg";

// Image
import PhotoUser from "../../../img/svg/Fotouser.svg"

// Styles
import style from "./style.module.scss";

export default function Header(props: IProps) {
	// -------------------------------------------------
	// Properties
	// -------------------------------------------------

	const { user } = props;

	const [isNavVisible, setNavVisibility] = useState(false);
	const [isUserVisible, setUserMenuVisibility] = useState(false);

	// Hooks
	const history = useHistory();

	const toggleNav = useCallback(
		(openNav) => {
			setNavVisibility(openNav);
		},
		[setNavVisibility],
	);

	const toggleUser = useCallback(
		(openUserMenu) => {
			setUserMenuVisibility(openUserMenu);
		},
		[setUserMenuVisibility],
	);

	const isMobileMenuActive = useCallback(() => {
		if (!isNavVisible) return style.navMobileHide;
		return ""
	}, [isNavVisible]);

	// -------------------------------------------------
	// Render
	// -------------------------------------------------
	return (
		<header className={style.header}>
			<div className={style.logo}>
				<div onClick={() => history.push("/")}>
					<Logo />
					<span>
						v
						{packageJson.version}
					</span>
				</div>
			</div>

			<nav className={`${style.nav} ${isMobileMenuActive()}`}>
				<button type="button" onClick={() => AuthService.logout()} className={style.navMobileNone}>
					<Icon name="ph-sign-out" />
				</button>
			</nav>
			<div className={style.user} onClick={() => toggleUser(!isUserVisible)}>
				<span>{user.name || "John Doe"}</span>
				<div className={style.userIcon}>
					<img
						src={user && user.avatar
							? user.avatar
							: PhotoUser}
						alt="Avatar"
					/>
				</div>
				<div
					className={style.userMenu}
					style={{ display: isUserVisible ? "block" : "none" }}
				>
					<ul>
						{/* <li>
							{user.name}
							<hr />
						</li>
						<li onClick={() => handleModalUserPhoto(true)}>
								Editar foto de Perfil
							</li>
							<li onClick={() => handleModalProfile(true)}>
								Editar Usuário
								<hr />
							</li> */}
						<li onClick={() => AuthService.logout()} className={style.exit}>
							Sair
							<Icon name="ph-sign-out" />
						</li>
					</ul>
				</div>
			</div>
			<button onClick={() => toggleNav(!isNavVisible)} className={style.burger}>
				<Icon name="ph-list" />
			</button>
		</header>
	)
}
