/* eslint-disable no-mixed-operators */
import { toast } from "@seidor/orbit-ds-core";
import Store from "../models";

export default (err: any) => {
	const url = (err.response && err.response.config.url) || "";
	if (
		err
		&& err.response
		&& err.response.status === 401
		&& err.response.config
		&& !url.includes("/logout")
	) {
		Store.dispatch.login.logout();
		toast({
			delay: 5000,
			type: "attention",
			description: "Sua sessão expirou, favor autenticar a sua conta novamente.",
			title: "Atenção!",
		});
	}

	if (err && err.response && err.response.status === 429) {
		toast({
			delay: 5000,
			type: "attention",
			description: "Muitas requisições foram feitas em um curto período de tempo, favor aguardar.",
			title: "Atenção!",
		});
	}

	if (err && err.response && err.response.status === 413) {
		toast({
			delay: 5000,
			type: "attention",
			description: "Arquivo enviado é grande, o limite máximo é 1MB, favor enviar novamente.",
			title: "Atenção!",
		});
	}

	if (
		err
		&& err.response
		&& (err.response.status === 502 || err.response.status === 500)
		&& err.response.config
		&& !url.includes("/user")
	) {
		toast({
			delay: 5000,
			type: "attention",
			description: "Nossos servidores estão instáveis, favor aguardar.",
			title: "Atenção!",
		});
	}

	if (err && err.response && err.response.status === 403) {
		const message =			err.response && err.response.data && err.response.data.customErrorMessage
			? err.response.data.customErrorMessage
			: "Você não tem autorização para acessar este módulo, favor entrar em contato com o responsável.";
		const details = err.response && err.response.data ? err.response.data.path : {};
		toast({
			delay: 5000,
			type: "attention",
			description: message,
			title: "Atenção!",
		});
		Store.dispatch.location.fetchRedirectLocationError({
			status: true,
			path: "/unauthorized",
			details,
			message,
		});
	}
};
