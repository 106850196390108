// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import { IModules } from "../../interfaces/modules";

export default createModel<IModules>({
	name: "module.modules",
	endpoint: {
		general: "/authservice/api/modules",
	},
	messages: {
		success: {
			update: "Módulo atualizado com sucesso",
			create: "Módulo criado com sucesso",
		},
		error: {
			general: "Ocorreu um erro ao buscar os módulos",
			update: "Ocorreu um erro ao atualizar o módulo",
			create: "Ocorreu um erro ao criar o módulo",
		},
	},
	effects: () => ({
		getAll: () => {
			return {
				endpoint: "/authservice/api/modules?page=1&size=100&sortParam=name&sortOrder=asc",
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os módulos",
			}
		},
		getById: (id: string) => {
			return {
				endpoint: `/authservice/api/modules/${id}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o Módulo.",
			}
		},
		getByTenantId: (id: string) => {
			return {
				endpoint: `/authservice/api/tenantAccess/${id}/tenant`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os Módulos.",
			}
		},
		update: (data: any) => {
			return {
				endpoint: `/authservice/api/modules/${data.module_id}`,
				data,
				method: "put",
				message: "Módulo atualizado com sucesso",
				errorMessage: "Ocorreu um erro ao atualizar o módulo",
			}
		},
		create: (data: any) => {
			return {
				endpoint: "/authservice/api/modules",
				data,
				method: "post",
				message: "Módulo criado com sucesso",
				errorMessage: "Ocorreu um erro ao criar o módulo",
			}
		},
		active: (id: string) => {
			return {
				endpoint: `/authservice/api/modules/${id}/enable`,
				method: "put",
				message: "Módulo ativado com sucesso.",
				errorMessage: "Ocorreu um erro ao ativar o módulo.",
			}
		},
		inactive: (id: string) => {
			return {
				endpoint: `/authservice/api/modules/${id}/disable`,
				method: "put",
				message: "Módulo inativado com sucesso.",
				errorMessage: "Ocorreu um erro ao inativar o módulo.",
			}
		},
	}),
});
