// Packages
import route from "../../components/Modules/route";

route.guest(() => {
	route("/users", "module/Users");
	route("/users/:id/details", "module/Users/Details");
	route("/users/:id/update", "module/Users/Update");
	route("/users/create", "module/Users/Create");
	route("/users/:id/authorization", "module/Users/Parts/Authorization/items");
});
