// Packages
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, ToastContainer } from "@seidor/orbit-ds-core";
import cookie from "js-cookie";
import { instance as client } from "./js/components/Modules/client";

// Containers
import App from "./js/containers/App";
import Store from "./js/models";

// Services
import authservice from "./js/models/controller";

// Styles
import "semantic-ui-css/semantic.min.css";
import "./css/index.scss";

// -------------------------------------------------
// Routes
// -------------------------------------------------

import "./js/utils/routes/auth";
import "./js/utils/routes/users";
import "./js/utils/routes/tenants";
import "./js/utils/routes/modules";
import "./js/utils/routes/submodules";
import "./js/utils/routes/authorizations";
// Actions must be the last
import "./js/utils/routes/actions";

// -------------------------------------------------
// Headers Setup
// -------------------------------------------------

client.interceptors.request.use((config) => {
	const tenant = cookie.get("tenant");
	const accessToken = cookie.get("accessToken");
	const token = cookie.get("token");

	const newConfig = config;

	if (tenant) newConfig.headers["x-api-key"] = tenant;
	if (accessToken) newConfig.headers.accessToken = accessToken;
	if (token) newConfig.headers.token = token;

	return config;
});

client.interceptors.response.use((response) => {
	if (response.status === 401) {
		authservice.logout(true);
	}

	return response;
});

// -------------------------------------------------
// Start application
// -------------------------------------------------

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={Store}>
				<ThemeProvider themeName="AdmPortalTheme" language="pt-BR">
					<App />
					<ToastContainer />
				</ThemeProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root"),
);
