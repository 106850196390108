// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import UsersInterface, {
	IAddAuthorization,
	IAddToTenant, ICreateProfileData, IRemoveFromTenant, IUpdateProfileData,
} from "../../interfaces/users";
import { IAuthorizationPortalAdmWithSearchParam } from "../../interfaces/defaults";

export default createModel<UsersInterface>({
	name: "module.users",
	endpoint: {
		general: "/users",
	},
	messages: {
		success: {
			update: "Usuário atualizado com sucesso",
			create: "Usuário criado com sucesso",
		},
		error: {
			general: "Ocorreu um erro ao buscar os usuários",
			update: "Ocorreu um erro ao atualizar o usuário",
			create: "Ocorreu um erro ao criar o usuário",
		},
	},
	effects: () => ({
		getAll: () => {
			return {
				endpoint: "/users?page=1&size=20000&sortParam=name&sortOrder=asc",
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os usuários",
			};
		},
		getById: (id: string) => {
			return {
				endpoint: `users/${id}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o usuário.",
			};
		},
		create: (data: ICreateProfileData) => {
			return {
				endpoint: "/users",
				data,
				method: "post",
				message: "Usuário criado com sucesso",
				errorMessage: "Ocorreu um erro ao criar o usuário",
			};
		},
		update: (data: IUpdateProfileData) => {
			return {
				endpoint: `users/${data.user_id}`,
				data: {
					name: data.name,
					email: data.email,
					description: data.description,
				},
				method: "put",
				message: "Usuário atualizado com sucesso",
				errorMessage: "Ocorreu um erro ao atualizar o usuário",
			};
		},
		getAccessesByUserId: ({ id, page, size }: any) => {
			return {
				endpoint: `users/${id}/useraccesses?size=${size}&page=${page}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o usuário.",
			};
		},
		active: (id: string) => {
			return {
				endpoint: `users/${id}/enable`,
				method: "put",
				message: "Usuário ativado com sucesso.",
				errorMessage: "Ocorreu um erro ao ativar o usuário.",
			};
		},
		inactive: (id: string) => {
			return {
				endpoint: `users/${id}/disable`,
				method: "put",
				message: "Usuário inativado com sucesso.",
				errorMessage: "Ocorreu um erro ao inativar o usuário.",
			};
		},
		addToTenant: (data: IAddToTenant) => {
			return {
				endpoint: "users/add-to-tenant",
				data,
				method: "post",
				message: "Tenant associado com sucesso.",
				errorMessage: "Ocorreu um erro ao associar o tenant",
			};
		},
		removeFromTenant: (data: IRemoveFromTenant) => {
			return {
				endpoint: "users/remove-from-tenant",
				data,
				method: "post",
				message: "Tenant removido com sucesso.",
				errorMessage: "Ocorreu um erro ao remover o tenant",
			};
		},
		addAuthorization: (data: IAddAuthorization) => {
			return {
				endpoint: "authservice/api/authorizations/bulk",
				data,
				method: "post",
				message: "Autorização associada com sucesso",
				errorMessage: "Ocorreu um erro ao associar a autorização",
			};
		},
		getAuthorizationByUserId: ({
			id, size, page, sort,
		}: any) => {
			return {
				endpoint: `authservice/api/authorizations/user/${id}/all?size=${size}&page=${page}&sortParam=${sort.param}&sortOrder=${sort.order}`,
				method: "get",
			};
		},
		getAuthorizationByPortalAdm: (id: string) => {
			return {
				endpoint: `authservice/api/authorizations/user/${id}/tree-admin-portal`,
				method: "get",
			};
		},
		getAuthorizationByPortalAdmWithSearchParam: (data: IAuthorizationPortalAdmWithSearchParam) => {
			return {
				endpoint: `authservice/api/authorizations/user/${data.id}/tree-admin-portal?searchParam=${data.searchParam}`,
				method: "get",
			};
		},
	}),
});
