// Packages
import React from "react";
import { Dimmer } from "semantic-ui-react";
import { useSelector } from "react-redux";

// Styles
import style from "./style.module.scss";

// Interfaces
import { StateInterface } from "../../models";

// Assets
import Loader from "../../../img/svg/loader";

export default function ContainerLoader(props: { loading?: boolean }) {
	// -------------------------------------------------
	// Hooks
	// -------------------------------------------------

	const { loading } = useSelector((store: StateInterface) => store["main.controller"]);

	// -------------------------------------------------
	// Render
	// -------------------------------------------------

	return (
		<div className={style.container}>
			<Dimmer active={loading || props.loading} page>
				<Loader />
				<p className={style.text}>Aguarde...</p>
			</Dimmer>
		</div>
	);
}
