// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import IAuthorizations, { IGetByTenantIdWithSearchParam } from "../../interfaces/authorizations";
import { IAuthorizationPortalAdmWithSearchParam } from "../../interfaces/defaults";

export default createModel<IAuthorizations>({
	name: "module.authorizations",
	endpoint: {
		general: "/authservice/api/resources/tree",
	},
	messages: {
		success: {
			update: "Autorização atualizada com sucesso.",
			create: "Autorização criada com sucesso.",
		},
		error: {
			general: "Ocorreu um erro ao buscar as autorizações.",
			update: "Ocorreu um erro ao atualizar a autorização.",
			create: "Ocorreu um erro ao criar a autorização.",
		},
	},
	effects: () => ({
		getAll: () => {
			return {
				endpoint: "/authservice/api/resources/tree",
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar as autorizações.",
			}
		},
		getAllWithSearchParam: (searchParam: string) => {
			return {
				endpoint: `/authservice/api/resources/tree?searchParam=${searchParam}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar as autorizações.",
			}
		},
		getByActionId: (actionId: string) => {
			return {
				endpoint: `/authservice/api/authorizations/action/${actionId}/all`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar a autorização.",
			}
		},
		getByTenantId: (id: string) => {
			return {
				endpoint: `/authservice/api/tenantAuthorizations/${id}/tenant/tree`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar as autorizações.",
			}
		},
		getAuthorizationByPortalAdm: () => {
			return {
				endpoint: "/authservice/api/resources/tree-admin-portal",
				method: "get",
			}
		},
		getAuthorizationByPortalAdmWithSearchParam: (searchParam: IAuthorizationPortalAdmWithSearchParam) => {
			return {
				endpoint: `/authservice/api/resources/tree-admin-portal?searchParam=${searchParam}`,
				method: "get",
			}
		},
		getByTenantIdWithSearchParam: (data: IGetByTenantIdWithSearchParam) => {
			return {
				endpoint: `/authservice/api/tenantAuthorizations/${data.id}/tenant/tree?searchParam=${data.searchParam}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar as autorizações.",
			}
		},
		removeAuthorization: (id: string) => {
			return {
				endpoint: `authservice/api/authorizations/${id}`,
				method: "delete",
				message: "Autorização excluída com sucesso",
				errorMessage: "Ocorreu um erro ao remover a autorização",
			}
		},
	}),
});
