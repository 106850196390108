// Parts
import _Route from "./parts/route";
import _Group from "./parts/group";
import _Logged from "./parts/logged";
import _Guest from "./parts/guest";
import _Fallback from "./parts/fallback";
import _Module from "./parts/module";
import _buildRoutes from "./parts/buildRoutes";

// Interfaces
import { RouteInterface } from "./types";

// build route methods
const Route = _Route as RouteInterface;
Route.logged = _Logged;
Route.guest = _Guest;
Route.group = _Group;
Route.fallback = _Fallback;
Route.module = _Module;
Route.build = _buildRoutes;

export default Route;

// secondary
export { default as buildRoutes } from "./parts/buildRoutes";
export { getRoutes } from "./parts/data";
