// Parts
import { Options } from "../types";

// Interfaces
import optionsCB from "./options";

export default function guest(arg2: (() => void) | Options, arg3?: () => void): void {
	const cb = arg3 || (arg2 as () => void);
	const options = arg3 ? arg2 : {};

	optionsCB({ ...options, auth: "forbidden" }, cb);
}
