// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import { IAccesses, IAddAssociationProps } from "../../interfaces/accesses";

export default createModel<IAccesses>({
	name: "module.accesses",
	endpoint: {
		general: "/authservice/api/userAccess",
	},
	messages: {
		success: {
			create: "Accesso criado com sucesso.",
		},
		error: {
			general: "Ocorreu um erro ao buscar os acessos.",
			create: "Ocorreu um erro ao criar o acesso.",
		},
	},
	effects: () => ({
		getByUserId: (id: string) => {
			return {
				endpoint: `/authservice/api/userAccess/${id}/user`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o Acesso.",
			}
		},
		addAssociation: (data: IAddAssociationProps) => {
			return {
				endpoint: "/authservice/api/userAccess",
				data,
				method: "post",
				message: "Acesso associado com sucesso.",
				errorMessage: "Ocorreu um erro ao associar o Acesso.",
			}
		},
		removeAssociation: (id: string) => {
			return {
				endpoint: `/authservice/api/userAccess/${id}`,
				method: "delete",
				message: "Acesso excluído com sucesso.",
				errorMessage: "Ocorreu um erro ao remover o Acesso.",
			}
		},
	}),
});
