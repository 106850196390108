// Package
import createModel from "../../components/Modules/modelFactory";

// Interface
import SubmodulesInterface from "../../interfaces/submodules";

export default createModel<SubmodulesInterface>({
	name: "module.submodules",
	endpoint: {
		general: "/authservice/api/submodules",
	},
	messages: {
		success: {
			update: "Submódulo atualizado com sucesso",
			create: "Submódulo criado com sucesso",
		},
		error: {
			general: "Ocorreu um erro ao buscar os Submódulos",
			update: "Ocorreu um erro ao atualizar o Submódulo",
			create: "Ocorreu um erro ao criar o Submódulo",
		},
	},
	effects: () => ({
		getAll: () => {
			return {
				endpoint: "/authservice/api/submodules?page=1&size=100&sortParam=name&sortOrder=asc",
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os submódulos",
			}
		},
		getById: (id: string) => {
			return {
				endpoint: `/authservice/api/submodules/${id}`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar o Submódulo.",
			}
		},
		getByModuleId: (id: string) => {
			return {
				endpoint: `/authservice/api/submodules/${id}/module?size=1000&page=1&sortParam=name&sortOrder=asc`,
				method: "get",
				errorMessage: "Ocorreu um erro ao buscar os Submódulos.",
			}
		},
		update: (data: any) => {
			return {
				endpoint: `/authservice/api/submodules/${data.submodule_id}`,
				data,
				method: "put",
				message: "Submódulo atualizado com sucesso",
				errorMessage: "Ocorreu um erro ao atualizar o submódulo",
			}
		},
		create: (data: any) => {
			return {
				endpoint: "/authservice/api/submodules",
				data,
				method: "post",
				message: "Submódulo criado com sucesso",
				errorMessage: "Ocorreu um erro ao criar o submódulo",
			}
		},
		active: (id: string) => {
			return {
				endpoint: `/authservice/api/submodules/${id}/enable`,
				method: "put",
				message: "Submódulo ativado com sucesso.",
				errorMessage: "Ocorreu um erro ao ativar o submódulo.",
			}
		},
		inactive: (id: string) => {
			return {
				endpoint: `/authservice/api/submodules/${id}/disable`,
				method: "put",
				message: "Submódulo inativado com sucesso.",
				errorMessage: "Ocorreu um erro ao inativar o Submódulo.",
			}
		},
	}),
});
