// Interfaces
import { RematchStore } from "@rematch/core";
import ServiceInterface from "../types";

export default function ServiceInitializerDecorator(store: RematchStore<any, any>, model?: string): ClassDecorator {
	return function decorator(target) {
		const classModel = target as unknown as ServiceInterface;
		classModel.$store = store;
		classModel.$model = model;
	}
}
