import { Options, Route } from "../types";

let context: Options = { exact: true, module: undefined };
let routes: Route[] = [];
let cbs: (() => void)[] = [];

// -------------------------------------------------
// Context
// -------------------------------------------------

export const getContext = (): Options => context;

export const setContext = (newContext: Partial<Options>): void => {
	context = { ...newContext };
};

export const clearContext = (): void => {
	context = {};
};

// -------------------------------------------------
// Routes
// -------------------------------------------------

export const getRoutes = (): typeof routes => routes;

export const addRoute = (view: string, path: string, options: Options): void => {
	// gather data
	const { prefix, ..._context } = getContext();
	const completepath = `/${(prefix === undefined ? "/" : prefix) + path}`.replace(
		/^(\\+|\/+)/gm,
		"/",
	);
	const clearview = view
		.replace(/(\\+|\/+)/gm, "/")
		.replace(/(\\|\/)$/gm, "")
		.replace(/^(\\|\/)/gm, "");

	// push to routes
	routes.push({
		..._context,
		view: clearview,
		path: completepath,
		...options,
	});
};

export const clearRoutes = (): void => {
	routes = [];
};

// -------------------------------------------------
// Context
// -------------------------------------------------

export const getCallbacks = (): typeof cbs => cbs;

export const addCallback = (newCallback: () => void): void => {
	cbs.push(newCallback);
};

export const clearCallbacks = (): void => {
	cbs = [];
};
