// Interfaces
import { Route } from "../types";

// Data
import { clearCallbacks, getCallbacks, getRoutes } from "./data";

export default function buildRoutes(): Route[] {
	let cbs = getCallbacks();

	while (cbs.length > 0) {
		clearCallbacks();

		// run all groups
		for (let i = 0; i < cbs.length; i += 1) {
			cbs[i]();
		}

		// break loop if no callbacks left
		cbs = getCallbacks();
	}

	// sort routes
	const routes = getRoutes();

	routes.sort((a, b) => {
		let amultiplier = 1;
		let bmultiplier = 1;

		if (a.auth === "required") amultiplier -= 2;
		if (a.auth === "optional" || !a.auth) amultiplier -= 1;
		if (!a.exact && a.auth === "required") amultiplier += 2;

		if (b.auth === "required") bmultiplier -= 2;
		if (b.auth === "optional" || !b.auth) bmultiplier -= 1;
		if (!b.exact) bmultiplier += 2;

		return amultiplier - bmultiplier;
	});

	// build components
	return routes;
}
