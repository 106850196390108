// Packages
import route from "../../components/Modules/route";

route.guest(() => {
	route("/tenants", "module/Tenants");
	route("/tenants/:id/details", "module/Tenants/Details");
	route("/tenants/:id/update", "module/Tenants/Update");
	route("/tenants/create", "module/Tenants/Create");
	route("/tenants/:id/authorization", "module/Tenants/Parts/Authorization/items");
});
